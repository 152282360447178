import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router/router'
import common from './modules/common'
import menu from './modules/menu'
import tagView from './modules/tagView'
import user from './modules/user'
import { storage } from '@/utils/storage'

Vue.use(Vuex)


export default new Vuex.Store({
  state: {
    width: storage.getItem('width') || 99,
    height: storage.getItem('height') || 99,
    changeHeight: true,
    pageParms: {
      index: 1,
      size: 20,
      sizes: [10, 20, 50, 100, 200],
      layout: 'total, sizes, prev, pager, next, jumper',
    },
  },
  mutations: {
    setWidth: (state, n) => {
      const publicObj = 180 + 20 + 20
      const obj = {
        max: n,
        main: n - publicObj,
        search: n - publicObj - 260,
      }
      state.width = obj
      storage.setItem('width', obj)
    },
    setChangeHeight: (state) => {
      state.changeHeight = Math.random()
    },
    backLogin: () => {
      router.push('/login')
      // window.location.reload(true)
    },
    setHeight: (state, n) => {
      const publicHeight = 15 + 45 + 50 // 必缄高度
      const height = n || state.height.max
      const objHeight = {
        max: height, // 净高度
        main: height - publicHeight, // 基础减去 内部部分外的高度
      }
      state.height = objHeight
      storage.setItem('height', objHeight)
    },
  },
  getters: {
    getWidth: state => {
      return state.width
    },
    getHeight: state => {
      return state.height
    },
    getChangeHeight: state => {
      return state.changeHeight
    },
  },
  modules: {
    common,
    menu,
    tagView,
    user,
  },
})
