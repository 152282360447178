class MyStorage {

  constructor(store = window.sessionStorage, prefix = 'master_demo_') {
    this.storage = store
    this.prefix = prefix
  }

  clear() {
    this.storage.clear()
  }

  clearAll() {
    window.localStorage.clear()
    window.sessionStorage.clear()
  }

  setItem(key, value) {
    this.storage.setItem(`${this.prefix}${key}`, JSON.stringify({
      data: value,
      time: Date.now(),
    }))
  }

  getItem(key) {
    const value = this.storage.getItem(`${this.prefix}${key}`)
    try {
      return JSON.parse(value).data
    } catch (_e) {
      return null
    }
  }

  removeItem(key) {
    this.storage.removeItem(`${this.prefix}${key}`)
  }
}

export default MyStorage
export const baseStorage = new MyStorage(window.localStorage, 'erp_')

export const storage = new MyStorage()

export const localStorages = new MyStorage(window.localStorage, 'master_demo_')
